.tooltip {
  position: absolute;
  display: block;
  background-color: var(--bg-dark);
  color: var(--bg-primary);
  padding: 5px;
  border-radius: 10px;
  z-index: 200;
  white-space: nowrap;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
  