.row-sp-1{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2vh;
}

.exp-row{
    margin: 1rem 0;
    border: none;
    border-radius: 10px;
    width: 80vw;
    padding: 1vh 0;
    background: var(--bg-secondary);
    margin-bottom: 0.5rem;
}

.col-exp-left{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem;
    justify-content: center;
}

.col-exp-left .label{
    padding-bottom: 1rem;
}

.col-exp-left h4{
    font-size: larger;
}

.col-exp-left p{
    font-style: italic;
    font-size: medium;
}

.col-exp-right{
    display: flex;
    flex-direction: column;
    justify-content: space-center;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.col-exp-right h4{
    padding-bottom: 0.5rem;
}

@media only screen and (max-width: 800px) {
    .exp-row{
        width: 90vw;
    }
}
