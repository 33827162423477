.about-me{
  margin-bottom: 7vh;
}

.about-me-card{
  padding: 1.2rem;
}

.about-me-card p{
  margin-bottom: 1rem;
  line-height: 1.8rem;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.btn {
  padding: 10px 20px;
  min-width: 100px;
  background-color: #1c1c28; 
  color: #888484; 
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  cursor: default; 
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: #2a2a38; 
  color: #e0e0e0;
}

