.main-title-content{
    font-family: "Montserrat", sans-serif;
    font-size: smaller;
    font-weight: 600;
}

.main_page{
    width: 100%;
    height: 100vh;
    background: #000000;
    justify-content: space-around;
}

.main_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main_content .main-title{
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    font-weight: 900;
}

.main_content .main-sub{
    font-family: "EB Garamond", serif;
    font-size: 1rem;
    color: var(--bg-primary);
}

.main_img{
    background-size: cover;
    transform: scaleX(-1);
    opacity: 0.9;
}

.transition-content{
    font-family: "EB Garamond", serif;
    font-size: xxx-large;
}

.resume-download-btn {
    display: block;
    width: 80%;
    line-height: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    width: 8rem;
    padding: 0.5rem;
    border: 1px;
    font-weight: 600;
    font-size: 0.95rem;
    background: #333333;
    color: var(--bg-primary);
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.resume-download-btn:hover {
    background: var(--bg-primary);
    color: var(--bg-secondary);
    font-size: 1.05rem;
    transition: 200ms;
}

@media only screen and (max-width: 900px){
    .main_img{
        display: none;
    }

    .main_page{
        height: 80vh;
    }

    .main_content{
        flex: 0 0 80%;
        max-width: 80%;
    }

    .main-name{
        font-size: xx-large;
    }

    .transition-content{
        font-size: x-large;
    }
}
