.project-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 1rem;
}

.card-pro{
    margin: 1rem;
    border: 1px solid black;
    background: var(--bg-secondary);
    box-shadow: 0px 0px 0px var(--bg-primary);
    transition: var(--transition-speed-fast) ease-in-out;
}

.card-pro-img .card-img{
    min-height: 40vh;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(240,248,255,0.15);
}

.card-pro-img{
    width: 100%;
    height: 60%;
}

.card-pro-content{
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    padding: 1vh 0;
}

.card-pro-content h4{
    padding: 0.5rem 1rem;
}

.card-pro-content p{
    padding: 0.5rem 1rem;
}

.card-pro-content .btn-desc{
    padding: 0.5rem 1rem;
    display: flex;
    flex-wrap: wrap;
}

.btn-desc .info{
    padding: 0.2rem 0.5rem;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    background-color: #001524;
    color: var(--text-primary);
    white-space: nowrap;
    border: 1px;
    line-height: 1.5rem;
}

.card-pro-content .githublink{
    margin-top: auto;
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: 1rem;
    background: #00243f;
    color: var(--text-primary);
    white-space: nowrap;
    border: 1px;
    padding: 0rem 2rem;
    line-height: 3rem;
    display: flex;
    align-items: center;
}

.info:hover, .info:focus {
    opacity: .75;
}

.card-pro:hover{
    border: 1px solid var(--bg-primary);
    transform: scale(1.01);
    box-shadow: 0px 0px 15px var(--bg-primary);
}

.link-btn a{
    text-decoration: none;
}

.link-icon{
    transform: scale(0.8);
}


@media only screen and (max-height: 1000px) and (max-width: 1400px){
    .card-pro{
        min-height: 78vh;
    }
}