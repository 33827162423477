.resume_section{
    display: flex;
    justify-content: center;
    min-height: 50vh;
}

.resume_section .content{
    width: 80%;
}

.drive-card{
    margin-bottom: 0.25rem;
    color: #635d5d;
}

.drive-card a{
    color: #635d5d;
}

.drive-card a:hover{
    color: rgb(93, 93, 147);
}