.card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: var(--bg-secondary);
    border: 1px solid black;
    border-radius: 10px;
    overflow: hidden;
    margin: 1rem;
}

.card-width-award{
    width: 40vw;
    min-height: 20vh;
}

.card-width-award:hover{
    transform: scale(1.05) !important;
    transition: 300ms ease-in !important;

}

.card-award-content{
    padding: 3vh 1rem;
}

.card-logo {
    max-width: 40%;
    overflow: hidden;
    margin-right: 0.5rem;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
}

.card-logo{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
}

.card-award-title{
    font-weight: 600;
    padding-bottom: 4vh;
}

.card-award-issuer{
    font-style: italic;
    font-size: small;
}

.row{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

@media only screen and (max-width: 800px){
    .card-width-award{
        width: 80vw;
        height: 10rem;
    }

    .card-logo{
        display: none;
    }
}