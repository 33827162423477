.skill-container{
    margin: 0.8rem 0.8rem;
    margin-bottom: 7rem;
}

.skill-content{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.skill-item{
    margin: 0.8vw 0.8vw;
    padding: 0.3rem;
    border: none;
    background-color: var(--bg-secondary);
}

.skill-item img{
    height: 5rem;
    width: 5rem;
    margin: 0px 2rem;
}

.skill-item:hover{
    transform: scale(1.1) !important;
    transition: 200ms ease-in-out !important;
}