.navbar {
    position: fixed;
    background-color: var(--bg-secondary);
}

.navbar-nav{
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%
}

.nav-item{
    width: 100%;
    display: flex;
    justify-content: center;
}

.nav-link{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #708090;
    justify-content: center;
    filter: grayscale(100%) opacity(0.9);
    padding: 1vh;
}

.link-text{
    display: none;
}

#nav-logo-field{
    background: var(--bg-primary);
}

#nav-logo-field span{
    color: var(--bg-light);
    opacity: 1;
    font-family: "Poppins", monospace;
    font-size: 3rem;
    font-weight: 600;
}

.nav-item:first-child{
    margin-bottom: auto;
}

.nav-item:not(:first-child){
    padding: 1vh;
}

.nav-item:hover {
    transition: var(--transition-speed);
    background-color: var(--bg-dark-blue);
}

.nav-item:hover .nav-link{
    filter: grayscale(0%) opacity(1);
    transition: var(--transition-speed);
    color: var(--bg-primary);
}

.sx-nav{
    transform: scale(1.4);
}

@media only screen and (max-width: 800px){
    .navbar{
        bottom: 0;
        width: 100vw;
        height: 5rem;
        z-index: 1;
    }

    #nav-logo-field{
        display: none;
    }

    .navbar-nav{
        flex-direction: row;
    }

    .nav-link{
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 801px){
    main {
        margin-left: 5rem;
    }

    #nav-logo-field:hover{
        background: var(--bg-secondary);
    }

    #nav-logo-field:hover span{
        color: var(--bg-primary);
    }
    
    .navbar{
        top: 0;
        width: 5rem;
        height: 100vh;
    }

    .nav-link{
        width: 5rem;
    }
    
    .nav-item:last-child{
        margin-bottom: auto;
    }
}