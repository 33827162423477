.contact-form {
    padding: 2rem;
    font-family: monospace, sans-serif;
}

.contact-form input,textarea{
    font-family: monospace, sans-serif;
    display: block;
    width: 80%;
    line-height: 2rem;
    padding: 0.2rem 0.4rem;
}

.contact-form label{
    display: block;
    font-size: larger;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    font-family: 'Montserrat', sans-serif;
}

.contact-form .submit-btn{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 10rem;
    padding: 0.5rem;
    border: 1px;
    font-weight: 600;
    font-size: 1.0rem;
    background: var(--bg-light);
    color: var(--bg-primary);
    border-radius: 7px;
}

.submit-btn:disabled{
    opacity: 0.4;
}

.submit-btn:not([disabled]):hover{
    background: var(--bg-primary);
    color: var(--bg-secondary);
    transition: 200ms;
}

.icon-list{
    width: 100%;
    height: 15vh;
    background: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.icon-item a{
    padding: 0 0.6rem;
    text-decoration: none;
    color: var(--bg-primary);
    filter: grayscale(100%) opacity(0.8);
}

.copyright{
    font-family: monospace;
    margin-left: auto;
    padding-right: 1rem;
    display: none;
}

.copyright i{
    color: var(--bg-primary);
}

.icon-item a svg{
    transform: scale(1.3);
}

.icon-item a svg:hover{
    scale: 1.5;
    transform: rotate(8deg);
    transition: var(--transition-speed-fast);
}

.icon-item a:hover{
    filter: grayscale(0%) opacity(1);
    transition: var(--transition-speed);
    color: var(--bg-primary);
}

@media only screen and (max-width: 600px){
    .contact-form input,textarea{
        width: 100%;
    }

    .icon-list{
        padding-top: 5rem;
        padding-bottom: 8rem;
    }
}

@media only screen and (max-width: 800px){
    .icon-list{
        padding-top: 5rem;
        padding-bottom: 8rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (min-width: 801px){
    .icon-list{
        justify-content: start;
        padding-left: 4rem;
    }

    .icon-item a{
        padding: 0 1rem;
    }
}

@media only screen and (min-width: 900px){
    .copyright{
        display: inline;
    }
}