main hr{
    margin-top: 7vh;
    margin-bottom: 7vh;
    margin-left: 3vw;
    margin-right: 3vw;
    border: 0.2vh solid gray;
    box-shadow: 0px 0px 0.7vh rgb(30, 29, 29);
}

.topic{
    padding-bottom: 2vh;
    font-size: 2rem;
    font-family: 'Montserrat', sans-serif;
    /* font-family: "EB Garamond", serif; */
}

@media only screen and (min-width: 801px){
    .topic{
        padding-left: 5rem;
    }
}

@media only screen and (max-width: 800px){
    .topic{
        padding-left: 10vw;
    }
}