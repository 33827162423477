:root {
    --bg-primary: #ff9301;
    --bg-secondary: #0b0b13;
    --bg-tertiary: #E71D36;
    --bg-dark: #010101;
    --bg-dark-blue: #001523;
    --bg-dark1: #404258;
    --bg-dark2: #474E68;
    --bg-dark3: #50577A;
    --bg-dark4: #6B728E;
    --bg-light: #FFFFFF;
    --text-primary: #d1d1d1;
    --transition-speed: 500ms;
    --transition-speed-fast: 240ms;
    font-size: 16px;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body{
    background: var(--bg-dark);
    color: var(--text-primary);
    font-family: "Roboto", sans-serif;
    /* font-family: "EB Garamond", serif; */
    /* font-family: "Montserrat", sans-serif; */

}

body::-webkit-scrollbar {
    width: 0.25rem;
}

body::-webkit-scrollbar-track{
    background: var(--bg-dark);
}

body::-webkit-scrollbar-thumb{
    background: var(--bg-primary);
}

.row{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (min-width: 801px) {
    .col-md-3{
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
    .col-md-6 {
        flex: 0 0 47%;
        max-width: 47%;
    }
    .col-md-8 {
        flex: 0 0 66.666666%;
        max-width: 66.666666%;
    }
}

@media only screen and (max-width: 800px) {
    .col-md-3{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-md-8 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
